const EMAIL_OPT_IN = {
  NOT_ADD_USER: {
    name: "NOT_ADD_USER",
    method: "",
  },
  OPT_IN_AUTOMATICALLY: {
    name: "OPT_IN_AUTOMATICALLY",
    method: "Form Submission without consent",
  },
  OPT_IN_SUBMISSION: {
    name: "OPT_IN_SUBMISSION",
    method: "Passive consent",
  },
  ASK_USER: {
    name: "ASK_USER",
    method: "Active consent",
  },
  FIELD_NAME: "creatorOptInEmail",
};

const EMAIL_OPT_IN_FIELD_VALUES = [
  EMAIL_OPT_IN.OPT_IN_SUBMISSION.name,
  EMAIL_OPT_IN.ASK_USER.name,
];

const CLIENT_NAME = "{{Client Name}}";

const GOOGLE_PICKER = {
  SCOPES: "https://www.googleapis.com/auth/drive.file",
  ACTION: {
    PICKED: "picked",
  },
};

const GOOGLE = {
  OAUTH_SCRIPT: "https://apis.google.com/js/api.js",
  GIS_SCRIPT: "https://accounts.google.com/gsi/client",
  VALIDATE_ACCESS_TOKEN_API:
    "https://oauth2.googleapis.com/tokeninfo?access_token=",
  YOUTUBE_READONLY_SCOPE: "https://www.googleapis.com/auth/youtube.readonly",
};

const YOUTUBE_UPLOADING = {
  MAX_RESULTS: 50,
  PART: "snippet,contentDetails,status",
  STATUS_PRIVACY: {
    PUBLIC: "public",
    PRIVATE: "private",
  },
};

const FONT_FAMILY = {
  GOOGLE_SANS_TEXT: "Google Sans Text",
  GOOGLE_SANS: "Google Sans",
};

const MAX_FILE_SIZE = {
  BYTE: 5368709120,
  MB: 42000, // converted from 5368709120
};

const DEFAULT_WIDGET_SETTINGS = {
  maxFileSize: 300,
  maxVideoPerUpload: 3,
  maxPhotoPerUpload: 20,
  maxVideoLength: 600,
  isNewFlow: true,
};

export {
  EMAIL_OPT_IN,
  EMAIL_OPT_IN_FIELD_VALUES,
  CLIENT_NAME,
  GOOGLE_PICKER,
  GOOGLE,
  YOUTUBE_UPLOADING,
  FONT_FAMILY,
  MAX_FILE_SIZE,
  DEFAULT_WIDGET_SETTINGS,
};
