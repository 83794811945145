import { google as googleConfig } from "config";
import { DEFAULT_FIELD_NAME, KEYBOARD, PLACES_FIELDS } from "constants/common";
import WidgetBuilderContext from "contexts/WidgetBuilder";
import { useContext, useMemo, useState } from "react";
import usePlacesAutocompleteService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useTranslation } from "react-i18next";
import {
  StyledFormItemCustomWidget as StyledFormItem,
  StyledSelect,
} from "styled-components/WidgetBuilder/WidgetBuilderStyled";

const MailingAddress = (props) => {
  const { mailingAddress = {}, setFieldsValue } = props || {};
  const { t } = useTranslation();

  const { uploadInfo, setUploadInfo, isMobile } =
    useContext(WidgetBuilderContext);

  const [searchValue, setSearchValue] = useState("");

  const setRules = (fieldName) => {
    const rules = [];
    rules.push({
      required: mailingAddress.required,
      message: t("validation.required", {
        field: fieldName,
      }),
    });
    return rules;
  };

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesAutocompleteService({
    apiKey: googleConfig.mapKey,
    debounce: 1000,
  });

  const renderOptions = (options) => {
    return options?.map((option) => ({
      label: option.description,
      value: option.place_id,
    }));
  };

  const handleAddressComponents = (placeDetails) => {
    let mailingAddress = {};
    let zipCode = null;
    const address = placeDetails["address_components"];
    const placeId = placeDetails["place_id"];

    address.forEach((component, index) => {
      switch (component.types[0]) {
        case "country":
          mailingAddress.country = address[index]["long_name"];
          break;
        case "administrative_area_level_1":
          mailingAddress.state = address[index]["long_name"];
          break;
        case "locality":
          mailingAddress.city = address[index]["long_name"];
          break;
        case "postal_code":
          zipCode = address[index]["long_name"];
          break;
        case "street_number": {
          const streetNumber = address[index]["long_name"];
          if (streetNumber) {
            mailingAddress.street = `${streetNumber} `;
          }
          break;
        }
        case "route":
          if (mailingAddress.street) {
            mailingAddress.street += address[index]["long_name"];
          } else {
            mailingAddress.street = address[index]["long_name"];
          }
          break;
        default:
          break;
      }
    });

    if (placeId) {
      const addressDescription = placePredictions.find(
        (place) => place.place_id === placeId
      )?.description;
      if (addressDescription) {
        mailingAddress.description = addressDescription;
      }
    }

    setUploadInfo({ ...uploadInfo, mailingAddress, zipCode });
  };

  const handleSelectPlace = (placeId) => {
    if (placeId) {
      placesService?.getDetails(
        {
          placeId,
          fields: PLACES_FIELDS,
        },
        handleAddressComponents
      );
    }
  };

  const otherProps = useMemo(() => {
    if (!searchValue || isPlacePredictionsLoading) {
      return {
        notFoundContent: null,
      };
    }

    return {};
  }, [searchValue, isPlacePredictionsLoading]);

  return (
    <StyledFormItem
      name={DEFAULT_FIELD_NAME.mailingAddress}
      rules={setRules(t("widget_builder.body.mailing_address"))}
    >
      <StyledSelect
        isMobile={isMobile}
        className="dropdown-select_detail_widget"
        width="100%"
        placeholder={`${mailingAddress.name}${
          mailingAddress.required ? "*" : ""
        }`}
        onSearch={(input) => {
          getPlacePredictions({ input });
          setSearchValue(input);
        }}
        loading={isPlacePredictionsLoading}
        showSearch
        options={renderOptions(placePredictions)}
        filterOption={false}
        virtual={false}
        onSelect={handleSelectPlace}
        onInputKeyDown={(e) => {
          if (e.key === KEYBOARD.ENTER.STR && !placePredictions.length) {
            const { value } = e.target;
            setFieldsValue({
              [DEFAULT_FIELD_NAME.mailingAddress]: value,
            });
            setUploadInfo({
              ...uploadInfo,
              mailingAddress: {
                street: value,
              },
            });
          }
        }}
        allowClear={!!uploadInfo.mailingAddress && !isPlacePredictionsLoading}
        onClear={() => {
          setUploadInfo({ ...uploadInfo, mailingAddress: {}, zipCode: null });
        }}
        {...otherProps}
      />
    </StyledFormItem>
  );
};

export default MailingAddress;
