import { IconGoogleDrive } from "assets/img/icons";
import { google as googleConfig } from "config";
import { RESOLUTION, SUPPORTED_FILE, UPLOADER_TYPE } from "constants/common";
import { GOOGLE, GOOGLE_PICKER } from "constants/widgetBuilder";
import WidgetBuilderContext from "contexts/WidgetBuilder";
import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { validateOAuthGoogleToken } from "services/funcHelper";
import useInjectScript from "services/useInjectScript";
import utils from "services/utils";
import { storeData } from "stores/actions";
import { StyledSourceIcon } from "styled-components/WidgetBuilder/WidgetUploadSourceStyled";
import loadScript from "load-script";

const GoogleDrive = () => {
  const { t } = useTranslation();
  const { setGoogleDrive, setActiveUploaderType, widgetInfo, handleErrorMsg } =
    useContext(WidgetBuilderContext);
  const { widgetSettingsDTO = {} } = widgetInfo || {};
  const BUTTON_ID = "google_picker_btn";
  const STYLE_ID = "google_picker_style_id";
  const dispatch = useDispatch();
  const { googleAccessToken } = useSelector((state) => state.widgets);

  const [tokenClient, setTokenClient] = useState(null);

  useEffect(() => {
    loadScript(GOOGLE.OAUTH_SCRIPT, onApiLoad);
    loadScript(GOOGLE.GIS_SCRIPT, gisLoaded);
  }, []);

  const onApiLoad = () => {
    window.gapi.load("auth");
    window.gapi.load("picker");
  };

  const gisLoaded = () => {
    if (!window.google?.accounts?.oauth2) {
      console.error("Google Identity Services not initialized.");
      return;
    }

    const client = window.google.accounts.oauth2.initTokenClient({
      client_id: googleConfig.clientId,
      scope: GOOGLE_PICKER.SCOPES,
      callback: handleTokenResponse,
    });
    setTokenClient(client);
  };

  useLayoutEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleTokenResponse = (tokenResponse) => {
    dispatch(storeData({ googleAccessToken: tokenResponse.access_token }));
    openPicker(tokenResponse.access_token);
  };

  const handleResize = () => {
    // Set size for the picker modal when opened on the landscape view
    const isLandscape = window.matchMedia("(orientation: landscape)").matches;
    const styles = document.getElementById(STYLE_ID);
    if (styles) {
      styles.parentNode.removeChild(styles); // remove these styles
    }
    if (isLandscape && window.innerWidth < RESOLUTION.midTablet) {
      const css = `.picker.picker-dialog-content { height: ${
          window.innerHeight - 20
        }px !important; width: ${window.innerWidth - 20}px !important; }`,
        head = document.head || document.getElementsByTagName("head")[0],
        style = document.createElement("style");
      style.id = STYLE_ID;
      head.appendChild(style);
      style.type = "text/css";
      if (style.styleSheet) {
        // This is required for IE8 and below.
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }
    }
  };

  const originUrl = () => {
    // origin url is used in case a uploader is embedded an iframe
    if (
      window.location.ancestorOrigins &&
      window.location.ancestorOrigins.length > 0
    ) {
      // Access the origin of the immediate parent window
      const parentOrigin =
        window.location.ancestorOrigins[
          window.location.ancestorOrigins.length - 1
        ];
      return parentOrigin;
    } else {
      return window.location.protocol + "//" + window.location.host;
    }
  };

  const pickerCallback = async (data, oauthToken) => {
    if (data?.action === GOOGLE_PICKER.ACTION.PICKED) {
      if (data?.docs) {
        // Convert files which are suitable for the available validating function
        const files = data?.docs.map((item) => {
          return {
            ...item,
            type: item.mimeType,
            size: item.sizeBytes,
            uploadType: UPLOADER_TYPE.GOOGLE_DRIVE,
          };
        });
        const { errorMsg, isValidFiles } = await utils.validateUploadedFiles(
          files,
          "en",
          widgetSettingsDTO
        );

        if (isValidFiles && !errorMsg) {
          let filterMedias = files.map(async (item) => {
            const limitationError =
              (await utils.validateFileLimitation({
                file: item,
                widgetInfo,
                t,
                uploadType: UPLOADER_TYPE.GOOGLE_DRIVE,
              })) || "";

            return {
              fileId: item.id,
              fileName: item.name,
              mimeType: item.mimeType,
              limitationError,
            };
          });
          filterMedias = await Promise.all(filterMedias);
          setGoogleDrive({
            selectedMediaList: filterMedias,
            accessToken: oauthToken,
          });
          setActiveUploaderType(UPLOADER_TYPE.GOOGLE_DRIVE);
        } else {
          handleErrorMsg(errorMsg);
        }
      }
    }
  };

  const openPicker = (oauthToken) => {
    if (!window.google || !window.google.picker) {
      console.error("Picker API not loaded.");
      return;
    }

    const view = new window.google.picker.DocsView();
    // window.google.picker.ViewId.DOCS
    view.setParent("root");
    view.setIncludeFolders(true);
    view.setMimeTypes(
      `${SUPPORTED_FILE.GOOGLE_PHOTO}${SUPPORTED_FILE.GOOGLE_VIDEO}`
    );
    const picker = new window.google.picker.PickerBuilder()
      .addView(view)
      .enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
      .enableFeature(window.google.picker.Feature.NAV_HIDDEN)
      .enableFeature(window.google.picker.Feature.MINE_ONLY)
      .setDeveloperKey(googleConfig.developerKey)
      .setOAuthToken(oauthToken)
      .setCallback((data) => pickerCallback(data, oauthToken))
      .setTitle(t("widget_builder.google_picker.title"))
      .setOrigin(originUrl());
    picker.build().setVisible(true);
  };

  const onKeyDown = () => {
    const el = document.getElementById(BUTTON_ID);
    if (el) {
      el.click();
    }
  };

  const signIn = async () => {
    if (!tokenClient) {
      console.error("Token client is not initialized.");
      return;
    }

    tokenClient.requestAccessToken();
  };

  const onClickGoogleDriveBtn = async () => {
    if (googleAccessToken) {
      const isValidToken = await validateOAuthGoogleToken(googleAccessToken);
      if (isValidToken) {
        openPicker(googleAccessToken);
      } else {
        signIn();
      }
    } else {
      signIn();
    }
  };

  return (
    <StyledSourceIcon
      id={BUTTON_ID}
      tabIndex={0}
      cursor="pointer"
      size={37}
      onKeyDown={(e) => {
        utils.onKeyDown(e, onKeyDown);
      }}
      onClick={onClickGoogleDriveBtn}
    >
      <IconGoogleDrive />
    </StyledSourceIcon>
  );
};

export default GoogleDrive;
