import { Button, Checkbox, Form, Input, Modal, Select, Tooltip } from "antd";
import TextArea from "antd/lib/input/TextArea";
import styled, { css, keyframes } from "styled-components";
import { IconUploadDragger } from "../../assets/img/icons";
import {
  HEIGHT_DRAG_AND_DROP,
  MAX_SIZE_SCREEN_DETAIL_WIDGET,
  PADDING_WIDGET,
  PREFIX_CLS,
} from "../../constants/common";
import THEME from "../../constants/themes";
import utils from "../../services/utils";
import { StyledFlex, StyledIcon } from "../Common/CommonStyled";

const convertToPixel = (size) => {
  return `${(size * 14).toFixed(0)}px`;
};

const opacity = 0.15;

const arrow = keyframes`
   0% { top: 50%; }
  75% { top: 55%; }
  100% { top: 50%; }
`;

const arrowLeft = keyframes`
   0% { right: 0; }
  75% { right: 10px; }
  100% { right: 0; }
`;

export const StyledSubmitButton = styled(({ ...props }) => (
  <Button {...props} />
))`
  &,
  &:hover,
  &:focus {
    background-color: ${({ backgroundColor }) => backgroundColor};
    width: ${({ width }) => width || "100%"};
    border-radius: 34px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 0;
    height: ${({ isMobile }) => (isMobile ? "40px" : "52px")};
    font-size: ${({ isMobile }) =>
      isMobile ? `${convertToPixel(1.335)}` : `${convertToPixel(1.143)}`};
    ${({ isMobile }) => isMobile && "margin-top: 32px;"};
    color: ${({ color }) => `${color || THEME.colors.white} !important`};
    pointer-events: ${({ isDisabled }) => (isDisabled ? "none" : "unset")};
    opacity: ${({ isDisabled }) =>
      isDisabled ? THEME.disabledOpacity : "unset"};
    margin: ${({ margin }) => margin};
  }

  &:focus {
    outline: ${({ highlight }) => `2px solid ${highlight} !important`};
  }

  &.has_url {
    background-color: unset;
    ${({ backgroundUrl }) =>
      backgroundUrl && `background-image: url(${backgroundUrl})`};
    background-repeat: no-repeat;
    background-size: cover;
  }
`;

export const StyledWidgetFooter = styled.div`
  height: fit-content;
  min-height: 10px;
  position: relative;
  margin: ${({ margin }) => margin || "40px auto 24px"};
  text-align: center;
`;

export const StyledDetailWidget = styled.div`
  position: relative;
  ${({ padding }) => padding && `padding: ${padding} !important`};
  background: ${({ bgColor }) => bgColor || THEME.colors.white};
  max-width: ${MAX_SIZE_SCREEN_DETAIL_WIDGET.widthDesktop}px;
  border-radius: 16px;
  padding: 20px
    ${({ isMobile }) =>
      isMobile ? PADDING_WIDGET.mobile : PADDING_WIDGET.desktop}px;
  width: ${({ isMobile }) =>
    isMobile ? `${MAX_SIZE_SCREEN_DETAIL_WIDGET.widgetMobile}px` : "100%"};
  margin: auto;
  transition: ease-out 0.3s;
  overflow: hidden auto;

  &.gemini {
    max-width: 792px;

    @media only screen and (max-width: 500px) {
      width: 100%;
      border-radius: unset;
      padding: 0 32px !important;
      overflow: visible;
    }
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${THEME.colors.gray4};
    border-radius: 20px;
  }
`;

export const StyledWrapperDetailWidget = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  padding: 32px 0;
  position: relative;

  &::before {
    ${(props) =>
      props.bgUrl
        ? css`
            background-image: url(${props.bgUrl});
            background-size: cover;
            background-repeat: no-repeat;
          `
        : css`
            background: ${props.bgColor};
          `}

    content: "";
    height: 100%;
    width: 100%;
    left: 0;
    position: fixed;
    top: 0;
    will-change: transform;
    z-index: -1;
  }

  &:focus {
    outline: none;
  }

  &.gemini-wrapper {
    @media only screen and (max-width: 500px) {
      background-color: ${THEME.colors.white};
    }
  }
`;

export const StyledUploader = styled.div`
  background: ${THEME.colors.white};
  max-width: ${MAX_SIZE_SCREEN_DETAIL_WIDGET.widthDesktop}px;
  border-radius: 16px;
  padding: ${({ isMobile }) => (isMobile ? "20px" : "50px 60px 20px 60px")};
  width: ${({ isMobile }) =>
    isMobile ? `${MAX_SIZE_SCREEN_DETAIL_WIDGET.widgetMobile}px` : "626px"};
  margin: auto;
  transition: ease-out 0.3s;

  &.script_tag {
    width: 100% !important;
    padding: 60px;
    max-height: unset;
    text-align: center;

    ${StyledSubmitButton} {
      width: 65%;
    }

    ${StyledWidgetFooter} {
      margin: 42px auto 24px;
    }
  }

  @media only screen and (max-width: 768px) {
    &.script_tag {
      padding: 40px 40px 20px;

      ${StyledSubmitButton} {
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 380px) {
    width: 90%;
    margin: 0 auto;
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${THEME.colors.gray4};
    border-radius: 20px;
  }
`;

export const StyledHeader = styled.div`
  margin: auto;
  text-align: center;
`;

export const StyledLogo = styled.img`
  overflow: hidden;
  transition: ease-out 0.3s;
  max-width: 100%;
`;

export const StyledWrapperSession = styled.div`
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}`}
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}`}
`;

export const StyledSubFooter = styled.i`
  color: ${THEME.colors.black};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ isMobile, fontSize }) =>
    fontSize
      ? fontSize
      : isMobile
      ? `${convertToPixel(0.534)}`
      : `${convertToPixel(0.857)}`};
`;

export const StyledLogoFooter = styled.img`
  height: 16px;
  margin-left: 7px;
  cursor: pointer;
`;

export const StyledUpload = styled.div`
  ${({ margin }) => margin && `margin: ${margin}`};
  cursor: pointer;
  border: 1px dashed #d9e4ef;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${({ bgColor }) => bgColor};
  height: ${({ isMobile }) =>
    isMobile ? HEIGHT_DRAG_AND_DROP.mobile : HEIGHT_DRAG_AND_DROP.desktop}px;

  &:focus {
    outline: 2px solid #d9e4ef;
  }
`;

export const StyledInnerDropzone = styled.div`
  &:focus-visible,
  &.focus {
    outline: ${({ color }) => `2px solid ${utils.convertToRgba(color, 0.75)}`};
    border-radius: 2px;
  }
`;

export const StyledUploadContainer = styled.div`
  height: ${({ isMobile }) =>
    isMobile ? HEIGHT_DRAG_AND_DROP.mobile : HEIGHT_DRAG_AND_DROP.desktop}px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  background-size: cover;
  background-position: 50%;

  .dropzone {
    position: absolute;
    opacity: 0.8;
    width: 100%;
    height: 100%;

    &.video {
      color: ${({ color }) => color};
    }
  }
`;

export const StyledSubText = styled.div`
  color: ${({ color }) => color};
  font-size: ${({ isMobile }) =>
    isMobile ? `${convertToPixel(0.712)}` : `${convertToPixel(1)}`};
  line-height: 1.33;
  margin: ${({ margin }) => margin || "7px 0 0 0 "};
  z-index: 1;
`;

export const StyledSubContentText = styled.div`
  color: ${({ color }) => color};
  font-size: ${({ isMobile }) =>
    isMobile ? `${convertToPixel(0.6)}` : `${convertToPixel(0.857)}`};
  line-height: 1.33;
`;

export const StyledTextDecoration = styled.span`
  text-decoration: underline;
`;

export const StyledTitle = styled.p`
  font-weight: 700;
  text-align: center;
  word-break: break-word;
  color: ${THEME.colors.black};
  font-size: ${({ isMobile }) =>
    isMobile ? `${convertToPixel(2.136)}` : `${convertToPixel(1.714)}`};
  line-height: 1;
  margin-bottom: ${({ isMobile }) => (isMobile ? "24px" : "12px")};
`;

export const StyledMessage = styled.div`
  font-weight: 400;
  text-align: center;
  word-break: break-word;
  color: ${({ color }) => color || THEME.colors.black};
  font-size: ${({ isMobile }) =>
    isMobile ? `${convertToPixel(1.335)}` : `${convertToPixel(1.143)}`};

  p {
    margin: unset;
  }
`;

export const StyledError = styled.div`
  word-break: break-word;
  color: ${({ color }) => color || THEME.colors.black};
  font-size: 14px;
  margin: ${({ margin }) => margin};
  white-space: pre-line;

  p {
    margin: 0 !important;
  }

  ul {
    margin-top: 0.75rem;
    padding-left: 2rem;
  }
`;

export const StyledFormContainer = styled.div``;

export const StyledFormItemCustomWidget = styled(({ ...props }) => (
  <Form.Item {...props} />
))`
  &.${PREFIX_CLS}-form-item {
    margin: 0 0 28px;
  }

  .${PREFIX_CLS}-form-item-label {
    height: 22px;
    padding: 0;
    margin: 0 0 6px 0;

    label {
      width: 100%;
    }
  }

  .${PREFIX_CLS}-form-item-explain {
    &-error {
      font-size: 12px;
      padding: 0 18px 0 18px;
    }
  }

  &.upload-file {
    height: ${({ isMobile }) =>
      isMobile ? HEIGHT_DRAG_AND_DROP.mobile : HEIGHT_DRAG_AND_DROP.desktop}px;
  }
`;

export const StyledFormItem = styled(Form.Item)`
  ${({ margin }) => margin && `margin: ${margin}`};
  ${({ padding }) => padding && `padding: ${padding}`};

  &.form_checkbox {
    .${PREFIX_CLS}-form-item-control-input {
      display: inline-block;
    }
  }

  ${StyledIcon} {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);

    animation-name: ${arrowLeft} !important;
    animation-duration: 1.25s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-play-state: running;

    -webkit-animation-name: ${arrowLeft};
    -webkit-animation-duration: 1.25s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-delay: 0s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-direction: normal;
    -webkit-animation-play-state: running;
  }
`;

export const StyledListTermCondition = styled.div`
  margin-bottom: 24px;

  &:focus,
  &:focus-visible {
    outline: none;
  }
`;

export const StyledLink = styled.a`
  word-break: break-word;
  font-weight: 400;

  &,
  &:hover {
    text-decoration: ${({ underline }) => underline || "underline"};
    color: ${({ color }) => color};
    cursor: pointer;
    ${({ margin }) => margin && `margin: ${margin}`};
    font-size: ${({ fontSize }) => fontSize || "14px"};
    transition: all 0.2s;

    &::after {
      display: ${({ required }) => (required ? "inline-block" : "none")};
      color: ${({ color }) => color};
      position: absolute;
      margin-left: 3px;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: "*";
    }
  }

  &:focus-visible {
    text-decoration: ${({ underline }) => underline || "underline"};
    font-weight: 600;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  display: flex;
  color: ${THEME.colors.darkBlue2};
  font-size: ${convertToPixel(1.068)} !important;

  &.${PREFIX_CLS}-checkbox-wrapper {
    width: 100% !important;

    .${PREFIX_CLS}-checkbox {
      align-self: unset !important;
      padding: 4px 0 0 0 !important;

      &-disabled + span {
        color: rgba(0, 0, 0, 0.4);
      }
    }
  }

  .${PREFIX_CLS}-checkbox-inner {
    border-radius: 2px !important;
    top: 2px !important;
  }

  .${PREFIX_CLS}-checkbox-checked {
    .${PREFIX_CLS}-checkbox-inner {
      border-color: ${({ color }) => `${color} !important` || "unset"};
    }

    &::after {
      border: unset;
    }
  }

  &:hover {
    .${PREFIX_CLS}-checkbox-inner {
      border-color: ${({ color }) => `${color} !important` || "unset"};
    }
  }

  .${PREFIX_CLS}-checkbox-checked {
    .${PREFIX_CLS}-checkbox-inner {
      background-color: ${({ color }) => `${color} !important` || "unset"};
    }

    &::after {
      border: unset;
    }

    &:hover {
      border-color: ${({ color }) => `${color} !important` || "unset"};
    }
  }

  &.${PREFIX_CLS}-checkbox-wrapper {
    width: fit-content;

    &:not(:first-child) {
      margin-top: 11px;
    }
    margin-left: 0;
  }

  .${PREFIX_CLS}-checkbox {
    & + span {
      max-width: calc(100% - 20px);
      padding: 0 0 0 8px;
    }
  }

  .text_term {
    word-break: break-word;
    font-weight: 400;
  }

  &.overflow_text {
    .text_term {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      word-break: break-word;

      &.text_term_truncation {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 40; /* number of lines to show */
        line-clamp: 40;
        -webkit-box-orient: vertical;
        white-space: normal;
      }
    }

    .${PREFIX_CLS}-tooltip-inner {
      width: 286px;
      max-height: 350px;
      overflow-y: auto;
      font-size: ${({ isMobile }) =>
        isMobile ? `${THEME.fontSizes.small}` : `${THEME.fontSizes.normal}`};
      background-color: ${THEME.colors.white};
      color: ${THEME.colors.black};
      border-radius: 4px;

      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: ${THEME.colors.gray5};
        border-radius: 20px;
      }
    }

    .${PREFIX_CLS}-tooltip-arrow {
      &::before {
        background: ${THEME.colors.white} !important;
      }

      &-content {
        &::before {
          background: ${THEME.colors.white} !important;
        }
      }
    }
  }

  &.checkbox_custom {
    &:hover {
      ${StyledLink} {
        &.highlight {
          font-weight: 700;
        }
      }
    }
  }

  .${PREFIX_CLS}-checkbox-wrapper:hover
    .${PREFIX_CLS}-checkbox-inner,
    .${PREFIX_CLS}-checkbox:hover
    .${PREFIX_CLS}-checkbox-inner,
    .${PREFIX_CLS}-checkbox-input:focus
    + .${PREFIX_CLS}-checkbox-inner {
    border-color: ${({ color }) => `${color} !important` || "unset"};
  }
`;

export const StyledRequiredCheckbox = styled.span`
  color: ${({ color }) => color || THEME.colors.orangeBase};
  margin-left: 2px;
`;

export const StyledListItem = styled.div`
  min-height: ${HEIGHT_DRAG_AND_DROP.desktop + 29}px;
`;

export const StyledSelect = styled(Select)`
  padding: 0;
  margin: ${({ margin }) => margin || "unset"};
  width: ${({ width }) => `${width} !important` || "100%"};

  &.dropdown-custom-widget {
    .${PREFIX_CLS}-select-selector {
      display: block;
      height: 88px;
      overflow-y: auto;
      border-radius: 2px;
    }
  }

  &.dropdown-select_detail_widget {
    .${PREFIX_CLS}-select-selector {
      color: rgb(103, 115, 125);
      min-height: ${({ isMobile }) => (isMobile ? "42px" : "32px")};
      border-radius: ${({ isMobile }) => (isMobile ? "24px" : "16px")};
      border: 1px solid #d9e4ef;
      background-color: #f5f9fd !important;
      font-size: ${({ isMobile }) =>
        isMobile ? `${convertToPixel(1.068)}` : `${convertToPixel(0.857)}`};
      line-height: ${({ isMobile }) =>
        isMobile ? "42px !important" : "34px !important"};
      padding: 0 11px;

      .${PREFIX_CLS}-select-selection-item {
        transition: none !important;
      }
    }

    .${PREFIX_CLS}-select-selection-placeholder {
      font-size: ${({ isMobile }) =>
        isMobile ? `${convertToPixel(1.068)}` : `${convertToPixel(0.857)}`};
      color: ${THEME.colors.darkBlue4};
      padding: 0 4px;
    }

    .${PREFIX_CLS}-select-selection-search {
      margin-inline-start: 4px;
    }

    &.${PREFIX_CLS}-select-single {
      min-height: ${({ isMobile }) => (isMobile ? "42px" : "34px")};

      .${PREFIX_CLS}-select-selection-placeholder {
        padding: ${({ isMobile }) => (isMobile ? "4.5px 0" : 0)};
      }

      .${PREFIX_CLS}-select-selector {
        padding: 0 14px;
      }

      .${PREFIX_CLS}-select-selection-search,
        .${PREFIX_CLS}-select-selection-item {
        height: ${({ isMobile }) => (isMobile ? "40px" : "30px")};
        line-height: ${({ isMobile }) => (isMobile ? "40px" : "30px")};
      }
    }

    &.${PREFIX_CLS}-select-multiple {
      min-height: ${({ isMobile }) => (isMobile ? "42px" : "34px")};

      .${PREFIX_CLS}-select-selection-item {
        background: #f5f5f5;
        border: 1px solid #d9e4ef !important;
        border-radius: 12px;
      }
    }
  }

  .${PREFIX_CLS}-select-dropdown {
    &.dropdown-custom-content {
      overflow: auto !important;

      .rc-virtual-list-holder {
        max-height: calc(50vh - 160px) !important;

        .rc-virtual-list-holder-inner {
          transform: translateY(0px) !important;
        }
      }
      .rc-virtual-list-scrollbar {
        display: none !important;
      }
    }
  }

  .dropdown-menu-hidden {
    display: none;
  }

  input::placeholder {
    color: transparent;
  }
`;

export const StyledInputItem = styled(({ ...props }) => <Input {...props} />)`
  width: 100%;
  border-radius: 16px;
  border: 1px solid #d9e4ef;
  background-color: #f5f9fd;
  color: ${THEME.colors.gray1};

  height: ${({ isMobile }) => (isMobile ? "42px" : "32px")};
  border-radius: ${({ isMobile }) => (isMobile ? "24px" : "16px")};
  padding: ${({ isMobile }) =>
    isMobile ? "4px 13px 5px 13px" : "4px 16px 6px 16px"};
  font-size: ${({ isMobile }) =>
    isMobile ? `${convertToPixel(1.068)}` : `${convertToPixel(0.857)}`};
  ${({ isMobile }) => isMobile && "line-height: 1.25"};
  &:first-child {
    ${({ isMobile }) => !isMobile && "margin-top: 0"};
  }

  &::placeholder {
    color: ${THEME.colors.darkBlue4};
  }
  &:-ms-input-placeholder {
    color: ${THEME.colors.darkBlue4};
  }

  &::-ms-input-placeholder {
    color: ${THEME.colors.darkBlue4};
  }
`;

export const StyledTextAreaItem = styled(({ ...props }) => (
  <TextArea {...props} />
))`
  width: 100%;
  border-radius: 16px;
  border: 1px solid #d9e4ef;
  background-color: #f5f9fd;
  color: ${THEME.colors.gray1};
  padding: ${({ isMobile }) => (isMobile ? "5px 13px" : "6px 16px")};
  font-size: ${({ isMobile }) =>
    isMobile ? `${convertToPixel(1.068)}` : `${convertToPixel(0.857)}`};
  ${({ isMobile }) => isMobile && "line-height: 1.25"};

  &::placeholder {
    color: ${THEME.colors.darkBlue4};
  }
  &:-ms-input-placeholder {
    color: ${THEME.colors.darkBlue4};
  }

  &::-ms-input-placeholder {
    color: ${THEME.colors.darkBlue4};
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: ${THEME.colors.gray5};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${THEME.colors.gray3};
    border-radius: 20px;
  }
`;

export const StyledIconUpload = styled(({ ...props }) => (
  <IconUploadDragger {...props} />
))`
  & > path {
    fill: ${(props) => props.fillIcon || THEME.colors.orangeBase};
  }
`;

export const StyledText = styled.div`
  padding: ${({ padding }) => padding};
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) =>
    fontSize ? convertToPixel(fontSize) : "14px"};
  text-align: ${({ align }) => align || "center"};
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight}`};
  ${({ margin }) => margin && `margin: ${margin}`};
  ${({ fontWeight }) => fontWeight && ` font-weight: ${fontWeight}`};
`;

export const StyledImg = styled.img`
  width: auto;
  padding: 0;
  height: auto !important;
  max-height: ${({ maxHeight }) => maxHeight || "350px"};

  &.thumbnail {
    width: ${({ count, isMobile }) =>
      count <= 1 && !isMobile ? "220px" : "100%"};
    height: ${({ isMobile }) =>
      isMobile ? "350px !important" : "300px !important"};
    border-radius: ${({ isMobile, count }) =>
      !isMobile && count > 1 ? "16px" : "6.2px"};
    object-fit: cover;
    margin: 0 auto;
  }
`;

export const StyledCarousel = styled.div`
  text-align: center;
  white-space: nowrap;
  overflow: auto;
  width: ${({ isMobile }) =>
    isMobile ? "calc(100% - 25px)" : "calc(100% - 80px)"};
  margin: 0 auto 24px auto;
  cursor: pointer;

  .slick-slide {
    padding: 0 6px;
  }

  &::-webkit-scrollbar {
    height: 4.6px;
  }

  &::-webkit-scrollbar-track {
    background: ${THEME.colors.gray5};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${THEME.colors.gray1};
    border-radius: 20px;
  }
`;

export const StyledCarouselItem = styled.div`
  display: inline-block;
  position: relative;
  margin: ${({ margin }) => margin || "0 20px 0 0"};

  ${StyledIcon} {
    position: absolute;
    bottom: 6px;
    right: 8px;
  }
`;

export const StyledTermConditions = styled(Modal)`
  .${PREFIX_CLS}-modal-header {
    text-align: center;

    .${PREFIX_CLS}-modal-title {
      font-size: 1.5rem;
      padding-right: 0;
      font-weight: 700;
      position: relative;
      line-height: 22px;

      ${StyledIcon} {
        position: absolute;
        right: -14px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .${PREFIX_CLS}-modal-content {
    border-radius: 2px;
    overflow: hidden;

    .${PREFIX_CLS}-modal-body {
      padding: 0;

      ${StyledIcon} {
        position: absolute;
        top: 50%;
        right: 3.5rem;
        transform: translateY(-50%);
        animation-name: ${arrow} !important;
        animation-duration: 1.25s;
        animation-timing-function: linear;
        animation-delay: 0s;
        animation-iteration-count: infinite;
        animation-direction: normal;
        animation-play-state: running;

        -webkit-animation-name: ${arrow};
        -webkit-animation-duration: 1.25s;
        -webkit-animation-timing-function: linear;
        -webkit-animation-delay: 0s;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-direction: normal;
        -webkit-animation-play-state: running;
      }

      .rpv-zoom-popover-target {
        padding: 8px 0 !important;
      }

      svg.rpv-core-icon {
        width: 14px !important;
      }
    }

    .${PREFIX_CLS}-modal-footer {
      padding: 10px 16px;
    }

    button {
      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
  }

  @media only screen and (max-width: 576px) {
    .rpv-zoom-popover-target-scale {
      font-size: 14px;
    }

    .handle-page-btn {
      font-size: 14px;

      svg.rpv-core-icon {
        width: 12px !important;
      }
    }
  }
`;

export const StyledModalContent = styled.div`
  padding: 24px;
  font-size: 16px;
  max-height: ${({ isMobile }) => (isMobile ? "65vh" : "75vh")};
  overflow: auto;
  white-space: pre-line;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: ${THEME.colors.white};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${THEME.colors.gray4};
    border-radius: 20px;
  }

  &:focus {
    outline: none;
  }
`;

export const StyledTabItem = styled(StyledFlex)`
  position: relative;
  width: 100%;
  height: 100%;
  font-weight: 600;
  text-align: center;

  ${StyledText} {
    width: 100%;
    position: absolute;
    z-index: 99;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.active {
    color: ${THEME.colors.white};

    &:focus {
      outline: none;
    }
  }
`;

export const StyledUploaderTab = styled(StyledFlex)`
  height: 45px;
  border-radius: 8px 8px 0px 0px;
  overflow: hidden;
  margin-bottom: 8px;
  cursor: pointer;

  ${StyledTabItem} {
    &.active {
      background-color: ${(props) => props.activeColor};
    }

    &:not(.active) {
      border-bottom: ${(props) => `2px solid ${props.activeColor}`};

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: ${(props) =>
          props.activeColor
            ? utils.convertToRgba(props.activeColor, opacity)
            : "unset"};
      }

      color: ${(props) => props.activeColor};
    }
  }
`;

export const StyledTooltip = styled(Tooltip)`
  &.tooltip_text {
    display: flex;
  }
`;

export const StyledUploadTerms = styled.div`
  width: fit-content;
  position: absolute;
  right: 0;
  position: absolute;
  top: ${({ isMobile }) => (isMobile ? "-0.75rem" : "-0.5rem")};
`;

export const StyledIconGGTranslate = styled.img`
  margin-right: 6px;
`;

export const StyledAgreeButtonContainer = styled.div`
  width: 100%;
  text-align: center;
`;

export const StyledDropdown = styled.div`
  position: relative;

  &:focus {
    outline: 2px solid red;
    border-radius: 50%;
  }
`;

export const StyledDropdownWrapper = styled.div`
  position: absolute;
  bottom: 60px;
  right: 0;
  width: max-content;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
`;

export const StyledGoogleTranslate = styled.div`
  position: relative;
  margin: 24px 0 0 0;

  ${StyledIcon} {
    position: absolute;
    right: 10px;
    top: ${({ isMobile }) => (isMobile ? "20px" : "15px")};
  }

  .goog-te-gadget .goog-te-combo {
    padding: 0 10px 0 10px;
    width: 100%;
    height: ${({ isMobile }) => (isMobile ? "42px" : "32px")};
    border-radius: ${({ isMobile }) => (isMobile ? "24px" : "16px")};
    border: 1px solid #d9e4ef;
    background-color: #f5f9fd;
    color: ${THEME.colors.gray1};
    font-size: ${({ isMobile }) =>
      isMobile ? `${convertToPixel(1.068)}` : `${convertToPixel(0.857)}`};
    -webkit-appearance: none;
    -moz-appearance: none;

    option {
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      background-color: #fff;
    }

    &:hover {
      border-color: #40a9ff;
      border-right-width: 1px;
    }

    &:focus {
      border-color: #40a9ff;
      box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
      border-right-width: 1px;
      outline: 0;
    }

    &:focus-visible {
      outline: none;
    }
  }

  .goog-logo-link {
    font-weight: 600 !important;

    &:focus-visible {
      font-weight: 700 !important;
    }
  }
`;
