import tl from "./tl.json";
import qa from "./qa.json";
import prod from "./prod.json";
import qav2 from "./qav2.json";
import prodv2 from "./prodv2.json";

const env = process.env.REACT_APP_ENV || "tl";

let APIServer = qa.API_SERVER;
let AppServer = tl.APP_SERVER;
let S3Server = tl.S3_SERVER;
let HomeUrl = tl.HOME_URL;
let TikTok = {
  clientKey: tl.TIKTOK.CLIENT_KEY,
  clientSecret: tl.TIKTOK.CLIENT_SECRET,
};
let GoogleTranslateUrl = tl.GOOGLE_TRANSLATE_URL;
let PendoKey = tl.PENDO_KEY;
let ipstackKey = tl.IPSTACK;
let google = {
  clientId: tl.GOOGLE.CLIENT_ID,
  clientSecret: tl.GOOGLE.CLIENT_SECRET,
  developerKey: tl.GOOGLE.DEVELOPER_KEY,
  youtubeClientId: tl.GOOGLE.YOUTUBE_CLIENT_ID,
  mapKey: tl.GOOGLE.MAP_KEY,
};
let uploadContentCloudFontUrl = tl.UPLOAD_CONTENT_CLOUDFRONT_URL;
let cookieDomain = tl.COOKIE_DOMAIN;
let geminiWidgetId = tl.GEMINI_WIDGET_ID;
let instagram = {
  clientId: tl.INSTAGRAM.CLIENT_ID,
  clientSecret: tl.INSTAGRAM.CLIENT_SECRET,
};

let Env = "tl";
const trimedEnv = env.trim();
if (trimedEnv === "prod") {
  APIServer = prod.API_SERVER;
  AppServer = prod.APP_SERVER;
  S3Server = prod.S3_SERVER;
  HomeUrl = prod.HOME_URL;
  TikTok = {
    clientKey: prod.TIKTOK.CLIENT_KEY,
    clientSecret: prod.TIKTOK.CLIENT_SECRET,
  };
  GoogleTranslateUrl = prod.GOOGLE_TRANSLATE_URL;
  PendoKey = prod.PENDO_KEY;
  ipstackKey = prod.IPSTACK;
  Env = "prod";
  google = {
    clientId: prod.GOOGLE.CLIENT_ID,
    clientSecret: prod.GOOGLE.CLIENT_SECRET,
    developerKey: prod.GOOGLE.DEVELOPER_KEY,
    youtubeClientId: prod.GOOGLE.YOUTUBE_CLIENT_ID,
    mapKey: prod.GOOGLE.MAP_KEY,
  };
  uploadContentCloudFontUrl = prod.UPLOAD_CONTENT_CLOUDFRONT_URL;
  cookieDomain = prod.COOKIE_DOMAIN;
  geminiWidgetId = prod.GEMINI_WIDGET_ID;
  instagram = {
    clientId: prod.INSTAGRAM.CLIENT_ID,
    clientSecret: prod.INSTAGRAM.CLIENT_SECRET,
  };
} else if (trimedEnv === "qa") {
  APIServer = qa.API_SERVER;
  AppServer = qa.APP_SERVER;
  S3Server = qa.S3_SERVER;
  HomeUrl = qa.HOME_URL;
  TikTok = {
    clientKey: qa.TIKTOK.CLIENT_KEY,
    clientSecret: qa.TIKTOK.CLIENT_SECRET,
  };
  GoogleTranslateUrl = qa.GOOGLE_TRANSLATE_URL;
  PendoKey = qa.PENDO_KEY;
  ipstackKey = qa.IPSTACK;
  Env = "qa";
  google = {
    clientId: qa.GOOGLE.CLIENT_ID,
    clientSecret: qa.GOOGLE.CLIENT_SECRET,
    developerKey: qa.GOOGLE.DEVELOPER_KEY,
    youtubeClientId: qa.GOOGLE.YOUTUBE_CLIENT_ID,
    mapKey: qa.GOOGLE.MAP_KEY,
  };
  uploadContentCloudFontUrl = qa.UPLOAD_CONTENT_CLOUDFRONT_URL;
  cookieDomain = qa.COOKIE_DOMAIN;
  geminiWidgetId = qa.GEMINI_WIDGET_ID;
  instagram = {
    clientId: qa.INSTAGRAM.CLIENT_ID,
    clientSecret: qa.INSTAGRAM.CLIENT_SECRET,
  };
} else if (trimedEnv === "qav2") {
  APIServer = qav2.API_SERVER;
  AppServer = qav2.APP_SERVER;
  S3Server = qav2.S3_SERVER;
  HomeUrl = qav2.HOME_URL;
  TikTok = {
    clientKey: qav2.TIKTOK.CLIENT_KEY,
    clientSecret: qav2.TIKTOK.CLIENT_SECRET,
  };
  GoogleTranslateUrl = qav2.GOOGLE_TRANSLATE_URL;
  PendoKey = qav2.PENDO_KEY;
  ipstackKey = qav2.IPSTACK;
  Env = "qav2";
  google = {
    clientId: qav2.GOOGLE.CLIENT_ID,
    clientSecret: qav2.GOOGLE.CLIENT_SECRET,
    developerKey: qav2.GOOGLE.DEVELOPER_KEY,
    youtubeClientId: qav2.GOOGLE.YOUTUBE_CLIENT_ID,
    mapKey: qav2.GOOGLE.MAP_KEY,
  };
  uploadContentCloudFontUrl = qav2.UPLOAD_CONTENT_CLOUDFRONT_URL;
  cookieDomain = qav2.COOKIE_DOMAIN;
  geminiWidgetId = qav2.GEMINI_WIDGET_ID;
  instagram = {
    clientId: qav2.INSTAGRAM.CLIENT_ID,
    clientSecret: qav2.INSTAGRAM.CLIENT_SECRET,
  };
} else if (trimedEnv === "prodv2") {
  APIServer = prodv2.API_SERVER;
  AppServer = prodv2.APP_SERVER;
  S3Server = prodv2.S3_SERVER;
  HomeUrl = prodv2.HOME_URL;
  TikTok = {
    clientKey: prodv2.TIKTOK.CLIENT_KEY,
    clientSecret: prodv2.TIKTOK.CLIENT_SECRET,
  };
  GoogleTranslateUrl = prodv2.GOOGLE_TRANSLATE_URL;
  PendoKey = prodv2.PENDO_KEY;
  ipstackKey = prodv2.IPSTACK;
  Env = "prodv2";
  google = {
    clientId: prodv2.GOOGLE.CLIENT_ID,
    clientSecret: prodv2.GOOGLE.CLIENT_SECRET,
    developerKey: prodv2.GOOGLE.DEVELOPER_KEY,
    youtubeClientId: prodv2.GOOGLE.YOUTUBE_CLIENT_ID,
    mapKey: prodv2.GOOGLE.MAP_KEY,
  };
  uploadContentCloudFontUrl = prodv2.UPLOAD_CONTENT_CLOUDFRONT_URL;
  cookieDomain = prodv2.COOKIE_DOMAIN;
  geminiWidgetId = prodv2.GEMINI_WIDGET_ID;
  instagram = {
    clientId: prodv2.INSTAGRAM.CLIENT_ID,
    clientSecret: prodv2.INSTAGRAM.CLIENT_SECRET,
  };
}

export {
  APIServer,
  AppServer,
  S3Server,
  Env,
  HomeUrl,
  TikTok,
  GoogleTranslateUrl,
  PendoKey,
  ipstackKey,
  google,
  uploadContentCloudFontUrl,
  cookieDomain,
  geminiWidgetId,
  instagram,
};
